<template>
  <div class="question">
    <Note v-if="processStatus===0" />
    <Warn
      v-else-if="[1.5,2].includes(processStatus)"
      ref="warn"
      v-model="selectWarnValue"
      :disabled="processStatus===2"
      :canViewReport.sync="canViewReport"
    />
    <template v-else>
      <Topic
        :product="product"
        :productItem="productItem"
        :productItemsLen="productItemsLen"
        :productItemIndex="productItemIndex"
        @select="questionSelect"
      />
    </template>

    <div class="tool">
      <el-row v-if="processStatus===0">
        <el-col
          :span="24"
          class="text-right"
        >
          <el-button
            round
            class="linear-btn next"
            @click="processStatus=1"
          >
            下一步
          </el-button>
        </el-col>
      </el-row>
      <el-row v-else-if="processStatus===1.5">
        <el-col
          :span="12"
        >
          <el-button
            round
            class="linear-btn next"
            @click="processStatus=1"
          >
            上一步
          </el-button>
        </el-col>
        <el-col
          :span="12"
          class="text-right"
        >
          <el-button
            round
            class="linear-btn next"
            :disabled="!canViewReport"
            @click="submitEval"
          >
            查看报告
          </el-button>
        </el-col>
      </el-row>
      <el-row
        v-else
        :gutter="20"
      >
        <el-col :span="12">
          <el-button
            v-if="!productItemIndex"
            round
            class="linear-btn next"
            :disabled="!productIndex"
            @click="nextProduct(-1)"
          >
            上一步
          </el-button>
          <el-button
            v-else
            round
            class="linear-btn next"
            @click="nextItem(-1)"
          >
            上一题
          </el-button>
        </el-col>
        <el-col
          :span="12"
          class="text-right"
        >
          <el-button
            v-if="productItemIndex+1===productItemsLen"
            round
            :disabled="disableNext"
            class="linear-btn next"
            @click="nextProduct(1)"
          >
            下一步
          </el-button>
          <!-- <el-button
            v-else-if="[1.5,2].includes(processStatus)"
            round
            class="linear-btn next"
            @click="nextProduct(1)"
          >
            查看报告
          </el-button> -->
          <el-button
            v-else
            round
            :disabled="disableNext"
            class="linear-btn next"
            @click="nextItem(1)"
          >
            下一题
          </el-button>
        </el-col>
      </el-row>

      <div
        v-if="processStatus===0"
        class="time"
      >
        <img
          class="time-table"
          src="./images/time-table.png"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getOrgEvalProduct, createRecordDetail } from '@/api';

export default {
  name: 'Question',
  components: {
    Note: () => import('./components/note.vue'),
    Warn: () => import('./components/warn.vue'),
    Topic: () => import('./components/topic.vue'),
  },
  data() {
    return {
      products: [],
      productIndex: 0,
      productItems: [],
      productItemIndex: 0,
      isLoading: false,
      processStatus: 0,
      selectWarnValue: false,
      canViewReport: false
    };
  },
  computed: {
    product() {
      if (!this.productsLen) return {};
      return this.products[this.productIndex];
    },
    productsLen() {
      return (this.products || []).length;
    },
    productItem() {
      if (!this.productItemsLen) return {};
      return this.productItems[this.productItemIndex];
    },
    productItemsLen() {
      return (this.productItems || []).length;
    },
    disableNext() {
      return !this.productItem.itemOptionsChar;
    },
    evalRecordId() {
      return this.$route.query.evalRecordId;
    },
    id() {
      return this.$route.query.id;
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.keyBoard();
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyEvent);
  },
  methods: {
    init() {
      this.getOrgEvalProduct();
    },
    setProductItems() {
      this.productItems = this.products[this.productIndex].productItems;
    },
    async nextProduct(step) {
      const next = this.productIndex + step;
      // 返回到最开始
      if (next < 0) return;
      // 题目测试完成
      if (next + 1 > this.productsLen) {
        console.log('题目测试完成');
        const res = await this.createRecordDetail();
        if (!res) return;
        this.processStatus = 1.5;
        return;
      }
      if (next > 0 && step > 0) {
        const res = await this.createRecordDetail();
        if (!res) return;
      }
      this.productIndex = next;
      this.productItemIndex = step > 0 ? 0 : this.productItemsLen - 1;
      this.setProductItems();
    },
    async nextItem(step) {
      const next = this.productItemIndex + step;
      // 由于按钮限定，理论上不会走这一步
      if (next < 0) {
        if (this.productIndex <= 0) return;
        this.productIndex -= 1;
        this.setProductItems();
        this.productItemIndex = 0;
        return;
      }
      if (next + 1 > this.productItemsLen) {
        if (this.productIndex + 1 < this.productsLen) {
          this.productIndex += 1;
          this.setProductItems();
          this.productItemIndex = 0;
        }
        return;
      }
      if (step > 0) {
        const res = await this.createRecordDetail();
        if (!res) return;
      }
      this.productItemIndex = next;
    },
    // 获取测评产品
    getOrgEvalProduct() {
      const params = {
        id: this.id
      };
      getOrgEvalProduct(params).then((res) => {
        this.products = res.data || [];
        this.setProductItems();
      }, (err) => {
        this.products = [];
      });
    },
    // 创建测评明细
    createRecordDetail() {
      if (this.isLoading) return false;
      this.isLoading = true;
      const params = {
        evalRecordId: this.evalRecordId,
        productId: this.productItem.productId,
        productItemId: this.productItem.id,
        productItemSelect: this.productItem.itemOptionsChar
      };
      return createRecordDetail(params).then((res) => {
        if (res.code === 20000) {
          return true;
        }
        this.$message.warning(res.msg || '服务返回异常！');
        return false;
      }, (err) => {
        this.$message.warning('服务异常！');
        return false;
      }).finally((res) => {
        this.isLoading = false;
      });
    },
    // 私有的测评key
    setKey(key) {
      return `question_${this.id}_${key}`;
    },
    questionSelect(productItem) {
      this.$set(this.productItems[this.productItemIndex], 'itemOptionsChar', productItem.itemOptionsChar);
    },
    submitEval() {
      console.log('测评完成');
      if (!this.$refs.warn) {
        this.$message.warning('出错了！');
        return;
      }
      this.$refs.warn.saveEvalWarning((res) => {
        this.$message.success(res?.msg || '提交预警成功！');
        setTimeout(() => {
          this.$router.replace({
            name: 'eval.report',
            query: { ...this.$route.query, evalRecordId: this.evalRecordId }
          });
        }, 1500);
      }, (err) => {
        this.$message.warning('服务异常！');
      });
    },
    keyBoard() {
      document.addEventListener('keydown', this.keyEvent);
    },
    keyEvent() {
      const key = window.event.keyCode;
      // console.log(key);
      // 上一个37
      if (key === 37) {
        if (this.processStatus === 1.5) {
          this.processStatus = 1;
          return;
        }
        if (!this.productItemIndex) {
          this.nextProduct(-1);
        } else {
          this.nextItem(-1);
        }
        return;
      }
      // 下一个39
      if (key === 39) {
        if (this.processStatus === 0) {
          this.processStatus = 1;
          return;
        }
        if (this.disableNext) {
          return;
        }
        if (this.productItemIndex + 1 === this.productItemsLen) {
          this.nextProduct(1);
        } else {
          this.nextItem(1);
        }
      }
    },
    recordDetailStatus() {
      return createRecordDetail().then((res) => {
        if (res.code === 20000) {
          return true;
        }
        this.$message.warning(res.msg || '操作异常，请联系管理员');
        return false;
      }, (err) => {
        this.$message.warning('操作异常，请联系管理员');
        return false;
      });
    }
  },
};
</script>

<style lang="less" scoped>
.question{
  padding-top: 16px;
  margin: 0 auto;
  width: 500px;
  .tool{
    padding-bottom: 16px;
  }
  .no-data{
    height: 200px;
    line-height: 200px;
    text-align: center;
    background: #f8f8f8;
    margin-bottom: 16px;
    color: #999;
  }
  .time{
    &-table{
      width: 580px;
      margin: 0 -40px 20px -40px ;
    }
  }
}
</style>
